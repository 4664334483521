import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, {useState } from "react";
import { Route, Switch } from "react-router-dom";

import Product from "./pages/Product";
import Solution from "./pages/Solution";
import About from "./pages/About";
import Main from "./pages/Main";
import Contact from "./pages/Contact";


const App = () => {
    
    // Tested the line below with body, head, and a div with specific id.
    const [language,setLanguage] = useState('ko');
    const toggle = e => {
        setLanguage(e);
    }
    return (
      <>
        <Switch>
          <Route path="/" exact component={() => <Main toggle={e => toggle(e)} language={language}/>} />
          <Route path="/about" component={() => <About toggle={e => toggle(e)} language={language}/>} />
          <Route path="/product" component={() => <Product toggle={e => toggle(e)} language={language}/>} />
          <Route path="/solution" component={() => <Solution toggle={e => toggle(e)} language={language}/>} />
          <Route path="/contact" component={() => <Contact toggle={e => toggle(e)} language={language}/>} /> 
        </Switch>
      </>
    );
}

export default App;


